@import 'styles/theme';

.MarketsPopin {
  background-color: theme($colors, 'white');
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100%;
  margin-left: theme($spacings, 6);
  margin-right: theme($spacings, 6);

  @include from('md') {
    width: vh(80);
    max-height: vh(80);
    min-width: 450px;
    max-width: 700px;
    margin-left: 0;
    margin-right: 0;
  }

  .header {
    padding: {
      top: theme($spacings, 20);
      right: theme($spacings, 40);
      bottom: theme($spacings, 20);
      left: theme($spacings, 20);
    }

    text-align: left;
    margin-bottom: theme($spacings, 50);
    border-bottom: 1px solid theme($colors, 'silver-chalice');

    @include from('lg') {
      border-bottom: none;
      margin-bottom: theme($spacings, 40);
      text-align: center;
    }
  }

  .content {
    max-width: 350px;
    margin: 0 auto;
    margin-top: theme($spacings, 20);

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'padding-left',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'padding-right',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );

    @include from('lg') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'padding-left',
          'gutters': 0,
          'gaps': 0,
          'columns': 1,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'padding-right',
          'gutters': 0,
          'gaps': 0,
          'columns': 1,
        )
      );
    }
  }

  .title,
  .text {
    text-align: center;
  }

  .title {
    @include from('md') {
      padding-left: theme($spacings, 45);
      padding-right: theme($spacings, 45);
    }
  }

  .text {
    margin-top: theme($spacings, 10);

    @include from('md') {
      padding-left: theme($spacings, 15);
      padding-right: theme($spacings, 15);
    }
  }

  .selectors {
    margin-top: theme($spacings, 20);

    .item {
      .label {
        color: theme($colors, 'gray');
        margin-bottom: theme($spacings, 5);
      }

      & + .item {
        margin-top: theme($spacings, 12);
      }
    }
  }

  .button {
    margin-top: theme($spacings, 50);
    width: 100%;

    @include from('lg') {
      margin-top: theme($spacings, 40);
    }
  }
}
