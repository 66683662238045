@import 'styles/theme';

.Alerts {
  position: fixed;
  bottom: theme($spacings, 20);
  right: theme($spacings, 20);
  z-index: 10;
}

.Alert {
  position: relative;
  padding: theme($spacings, 30);
  box-shadow: 0px 0px 15px 3px rgba(theme($colors, 'black'), 0.05);
  border: 1px solid theme($colors, 'black');
  background-color: theme($colors, 'white');
  &.success,
  &.error {
    border: 1px solid theme($colors, 'black');
    background-color: theme($colors, 'white');
  }
}

.CloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.text {
  margin-top: theme($spacings, 10);
}
