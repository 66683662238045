@import 'styles/theme';

.RichText {
  ul,
  ol {
    margin-top: 1.45em;

    > li:before {
      content: '■';
      margin-right: 5px;
      margin-left: 5px;
    }

    li > * {
      display: inline;
    }
  }
}
