@import 'styles/theme';

.PhoneWrapper {
  position: relative;
}

.Phone {
  input {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: theme($colors, 'black');
    background-color: transparent;
    padding: theme($spacings, 10) theme($spacings, 15) theme($spacings, 10)
      theme($spacings, 50);
    transition: border 0.2s ease;

    &::placeholder {
      text-transform: uppercase;
      color: theme($colors, 'gray');
    }

    &:focus {
      border: solid 1px theme($colors, 'black');
    }

    &.withError {
      border: solid 1px theme($colors, 'red');

      &::placeholder {
        color: theme($colors, 'red');
      }
    }
  }

  + .errorList {
    margin-top: theme($spacings, 5);
  }

  .errors {
    color: theme($colors, 'red');
  }

  :global {
    .hide {
      display: none;
    }
    .v-hide {
      visibility: hidden;
    }

    .form-control.open {
      z-index: 2;
    }

    .flag-dropdown {
      display: inline;
      width: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 0;
    }

    .flag-dropdown:hover,
    .flag-dropdown:focus {
      cursor: pointer;
    }
    .flag-dropdown.open {
      z-index: 2;
      width: 100%;
    }

    .selected-flag {
      outline: none;
      position: relative;
      width: 50px;
      height: 100%;
      padding: 0;
      padding-left: theme($spacings, 10);
    }

    .selected-flag .flag {
      position: absolute;
      top: calc(50% - 1px);
      transform: translateY(-50%) scale(0.8);
    }

    .selected-flag .arrow {
      position: relative;
      top: 50%;
      margin-top: 1px;
      left: 30px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid theme($colors, 'gray');
    }
    .selected-flag .arrow.up {
      border-top: none;
      border-bottom: 4px solid theme($colors, 'gray');
    }
    .country-list {
      outline: none;
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 0;
      border: 1px solid theme($colors, 'axolotl');
      box-sizing: border-box;
      background-color: theme($colors, 'white');
      width: 100%;
      max-height: 224px;
      overflow-y: scroll;
    }
    .country-list .flag {
      display: inline-block;
      position: absolute;
      top: calc(50% - 3px);
      transform: translateY(-50%) scale(0.8);
      left: 10px;
    }

    .country-list .country {
      box-sizing: border-box;
      padding: theme($spacings, 13) 9px theme($spacings, 13) 50px;
      position: relative;
      text-transform: uppercase;
    }
    .country-list .country .dial-code {
      color: theme($colors, 'gray');
    }
    .country-list .country:hover {
      background-color: theme($colors, 'gray');
      color: theme($colors, 'white');
    }
    .country-list .country.highlight {
      background-color: theme($colors, 'gray');
      color: theme($colors, 'white');

      .dial-code {
        color: theme($colors, 'white');
      }
    }
    .country-list .country-name {
      margin-right: theme($spacings, 5);
    }
  }
}
