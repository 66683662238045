@import 'styles/theme';

$themes: (
  'gray': (
    'main': theme($colors, 'gray'),
    'contrast': theme($colors, 'black'),
  ),
  'black': (
    'main': theme($colors, 'black'),
    'contrast': theme($colors, 'white'),
  ),
  'white': (
    'main': theme($colors, 'white'),
    'contrast': theme($colors, 'black'),
  ),
  'transparent': (
    'main': theme($colors, 'transparent'),
    'contrast': theme($colors, 'gray'),
  ),
);

.SquaredCta {
  @include setThemeClasses($themes);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s linear, color 0.2s linear,
    border-color 0.2s linear;
  padding: theme($spacings, 11) theme($spacings, 10);
  color: var(--theme-main);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.hovered,
  &.loading {
    color: var(--theme-contrast);
    background-color: var(--theme-main);

    // Icon case
    path {
      fill: var(--theme-contrast);
    }
  }

  &.withBorder {
    border-color: var(--theme-main);
  }

  &.withBackground {
    color: var(--theme-contrast);
    background-color: var(--theme-main);

    // Icon case
    path {
      fill: var(--theme-contrast);
    }

    &.hovered {
      color: var(--theme-main);
      background-color: var(--theme-contrast);

      // Icon case
      path {
        fill: var(--theme-main);
      }
    }

    &.withBorder {
      border-color: var(--theme-contrast);
    }
  }

  .spinner {
    height: 1em;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 20px;
  }

  .line {
    position: absolute;
    bottom: 0px;
  }

  //Themes
  &.blackTheme {
    &.hovered {
      border-color: var(--theme-main);
    }
  }
}
