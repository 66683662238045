@use 'sass:math';
@import 'styles/theme';

.ContactPopin {
  background-color: theme($colors, 'white');
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100%;
  width: calc(100% - #{theme($spacings, 12)});
  margin-left: theme($spacings, 6);
  margin-right: theme($spacings, 6);

  @include from('md') {
    width: vh(80);
    max-height: vh(80);
    min-width: 450px;
    max-width: 700px;
    margin-left: 0;
    margin-right: 0;
  }

  .header {
    padding: {
      top: theme($spacings, 20);
      right: theme($spacings, 40);
      bottom: theme($spacings, 20);
      left: theme($spacings, 20);
    }

    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: theme($spacings, 30);
    border-bottom: 1px solid theme($colors, 'silver-chalice');
  }

  .content {
    margin: 0 auto;

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'padding-left',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'padding-right',
        'gutters': 1,
        'gaps': 0,
        'columns': 0,
      )
    );

    @include from('lg') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'padding-left',
          'gutters': 1,
          'gaps': 0,
          'columns': 0,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'padding-right',
          'gutters': 1,
          'gaps': 0,
          'columns': 0,
        )
      );
    }
  }

  .logoHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: theme($spacings, 12);

    .logo {
      width: theme($spacings, 40);

      path {
        fill: theme($colors, 'black');
      }
    }

    @include from('md') {
      margin-bottom: theme($spacings, 24);

      .logo {
        width: theme($spacings, 34);
      }
    }
  }

  .tabItem {
    margin-bottom: theme($spacings, 50);

    .columns {
      display: flex;
      flex-direction: column;
    }

    @include from('md') {
      margin-bottom: theme($spacings, 40);

      .columns {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .tabContactUs {
    .columns {
      .column {
        text-align: center;

        .contacts {
          margin-top: theme($spacings, 24);
        }

        .contactTitle {
          margin-top: theme($spacings, 10);
        }

        .contactText {
          margin-top: theme($spacings, 6);

          a {
            text-decoration: underline;
          }
        }

        &:last-child {
          margin-top: theme($spacings, 60);
        }
      }

      @include from('md') {
        .column {
          width: math.div(310, 666) * 100%;

          .contacts {
            margin-top: theme($spacings, 40);
          }

          .contactTitle {
            margin-top: theme($spacings, 20);
          }

          .contactText {
            margin-top: theme($spacings, 10);
          }

          &:last-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .tabSendMessage {
    .title {
      margin-bottom: theme($spacings, 30);
    }

    .columns {
      .column {
        &:nth-child(2) {
          margin-top: theme($spacings, 6);
        }
      }
    }

    .bottom {
      margin-top: theme($spacings, 40);

      @include from('md') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-left',
            'gutters': -1,
            'gaps': 0,
            'columns': 0,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': -1,
            'gaps': 0,
            'columns': 0,
          )
        );
      }
    }

    @include from('md') {
      margin-bottom: 0;

      .columns {
        .column {
          width: math.div(329, 666) * 100%;

          &:nth-child(2) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .title,
  .text {
    text-align: center;
  }

  .text {
    margin-top: theme($spacings, 10);
  }

  .successMessage {
    text-align: center;
    margin-bottom: theme($spacings, 30);
  }

  .inputContainer {
    .item {
      & + .item {
        margin-top: theme($spacings, 6);
      }
    }

    .textarea {
      height: 160px;
    }

    @include from('md') {
      height: 100%;

      .textarea {
        height: 100%;
      }
    }
  }

  .marketing {
    margin-top: theme($spacings, 15);
  }

  .button {
    width: 100%;
  }
}
