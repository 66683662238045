@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.label {
  /**
  * Grotesk
  */
  &-8-12-grotesk-regular {
    @include font-base(8, 12, 'AkzidenzGrotesk', 1.3, 0em, 'regular');
  }

  &-8-grotesk {
    @include font-uppercase();
    @include font-base(8, 8, 'AkzidenzGrotesk', 1.3, -0.01em, 'medium');
  }

  &-10-grotesk {
    @include font-uppercase();
    @include font-base(10, 10, 'AkzidenzGrotesk', 1.3, -0.01em, 'medium');
  }
  &-10-12-grotesk {
    @include font-base(10, 12, 'AkzidenzGrotesk', 1.3, 0em, 'regular');
  }
  &-11-grotesk-regular {
    @include font-uppercase();
    @include font-base(11, 11, 'AkzidenzGrotesk', 1.3, 0em, 'regular');
  }

  &-12-grotesk {
    @include font-uppercase();
    @include font-base(12, 12, 'AkzidenzGrotesk', 1.3, -0.01em, 'medium');
  }

  &-12-16-grotesk {
    @include font-base(12, 16, 'AkzidenzGrotesk', 1.3, -0.01em, 'medium');
  }

  &-24-grotesk {
    @include font-base(24, 24, 'AkzidenzGrotesk', 1.3, -0.01em, 'medium');
  }
  /**
  * Sang Bleu
  */
  &-12-sang-bleu {
    @include font-base(12, 12, 'SangBleu', 1.1, 0, 'light');
  }
}
