@import 'styles/theme';

.Slider {
  width: 100%;
  margin-top: theme($spacings, 16);

  .indicatorsContainer {
    width: 100%;
    margin-top: theme($spacings, 10);
    display: flex;
    justify-content: space-between;
  }

  .priceIndicator {
    border-radius: theme($spacings, 25);
    padding: theme($spacings, 5) theme($spacings, 10);
    border-width: 1px;
    border-style: solid;
  }

  .sliderContainer {
    padding: 0 theme($spacings, 10);
  }

  :global {
    .rc-slider-handle,
    .rc-slider-handle.rc-slider-handle-dragging {
      width: theme($spacings, 15);
      height: theme($spacings, 15);
      margin-top: -7px;
      border: 1px solid theme($colors, 'black');
      box-shadow: none;
    }

    .rc-slider-handle {
      opacity: 1;
    }

    .rc-slider-rail,
    .rc-slider-track {
      height: 1px;
      background: theme($colors, 'silver-chalice');
    }

    .rc-slider-track {
      background-color: theme($colors, 'black');
    }
  }
}
