@import 'styles/theme';

$width: 12px;
$height: 12px;

.Checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  .label {
    flex: 1;
    cursor: pointer;
    margin-left: theme($spacings, 5);

    a {
      text-decoration: underline;
      z-index: 1000;
    }
  }

  .input {
    position: absolute;
    margin-top: 0;
    opacity: 0;
    cursor: pointer;

    &:checked + .contentClassName {
      .checkmark {
        .checkmarkActive {
          opacity: 1;
        }
      }
    }
  }

  .contentClassName {
    display: flex;
    flex-direction: row;
    pointer-events: none;
  }
}

.checkmark {
  display: block;
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: $width;
  height: $height;
  overflow: hidden;
  border: 1px solid theme($colors, 'black');
}

.checkmarkActive {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: theme($colors, 'black');

  opacity: 0;
}
