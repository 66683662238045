@import 'styles/theme';

.NavigationBottomLinks {
  margin-bottom: -#{theme($spacings, 6)};

  .panelBottomTitle {
    display: block;
    margin-bottom: theme($spacings, 10);

    @include from('md') {
      margin-bottom: theme($spacings, 20);
    }
  }

  .panelBottomLinks {
    display: flex;
    flex-flow: row wrap;
  }

  .panelBottomLinkCta {
    white-space: nowrap;
    margin-bottom: theme($spacings, 6);
    margin-right: theme($spacings, 4);
    pointer-events: all;
  }
}
