@import 'styles/theme';

.InlineCta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: color 0.2s linear, border-color 0.2s linear;
  padding-bottom: 1px;

  overflow: hidden;

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.loading {
    pointer-events: none;
  }

  .isLoading {
    opacity: 0;
  }

  &.isDiv {
    cursor: default;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  .line {
    position: absolute;
    bottom: 0px;
    background-color: theme($colors, 'black');

    &.white {
      background-color: theme($colors, 'white');
    }

    &.silver-chalice {
      background-color: theme($colors, 'silver-chalice');
    }

    &.gray {
      background-color: theme($colors, 'gray');
    }

    &.concrete {
      background-color: theme($colors, 'concrete');
    }
  }
}
