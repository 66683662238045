@import 'styles/theme';

.ToastCenter {
  pointer-events: none;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: vh(100);
  z-index: 1001;

  .grid {
    display: block;
    position: relative;
    height: 100%;

    @include from('md') {
      max-width: theme($max, 'width');
      margin: auto;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    transform: translateZ(0px);
    will-change: opacity;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'black');
    opacity: 0;
  }

  .Toast {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    width: 100%;
    right: 0;
    bottom: 0;
    background-color: theme($colors, 'white');
    box-sizing: border-box;

    .close {
      height: 56px;
      width: 56px;
      padding: theme($spacings, 20);
      box-sizing: border-box;
      position: absolute;
      right: 0;
      top: 0;

      .icon {
        path {
          fill: theme($colors, 'black');
        }
      }
    }

    @include from('md') {
      right: theme($spacings, 20);
      bottom: theme($spacings, 20);
      min-height: auto;
      width: 415px;

      box-sizing: border-box;
    }
  }
}
