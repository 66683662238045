@import 'styles/theme';

#onetrust-consent-sdk {
  #onetrust-pc-btn-handler,
  #onetrust-close-btn-container > button {
    padding: 0 !important;
    margin: 0 !important;
    display: inline-flex;
    position: static !important;
    top: auto !important;
    flex-shrink: 0 !important;
    font-family: theme($fontFamilies, 'AkzidenzGrotesk') !important;
    text-transform: uppercase !important;
    font-weight: theme($fontWeights, 'bold') !important;
    color: theme($colors, 'black') !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
    border: none !important;
    width: fit-content !important;
    text-decoration: underline !important;

    &:hover {
      color: theme($colors, 'scorpion') !important;
    }
  }

  #onetrust-banner-sdk {
    box-sizing: border-box !important;
    padding: 0 !important;
    background: #fff !important;
    box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.12) !important;
    max-width: none !important;
    top: unset !important;
    bottom: 0 !important;
    width: 100% !important;
    transform: none !important;
    transform: translateX(-50%) !important;
    left: calc(50%) !important;

    .ot-sdk-row {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      padding: 30px !important;
      gap: 10px !important;

      @include from('xl') {
        display: grid !important;
        grid-template-columns: 1fr auto !important;
        grid-template-rows: repeat(2, auto) !important;
        gap: unset !important;
        column-gap: 20px !important;
        row-gap: 10px !important  ;
      }

      #onetrust-group-container {
        margin: 0 !important;

        @include from('xl') {
          grid-column: 1 / 2 !important;
          grid-row: span 2 !important;
          justify-self: center !important;
        }

        #onetrust-policy {
          margin: 0 !important;
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          gap: 5px !important;

          @include from('xl') {
            flex-direction: row !important;
            align-items: center !important;
          }

          &::before {
            content: '';
            background-image: url('../../public/images/logo.svg');
            background-size: 100%;
            flex-shrink: 0;
            background-repeat: no-repeat;
            width: 46px;
            height: 54px;
            display: block;

            @include from('xl') {
              margin-right: theme($spacings, 10) !important;
            }
          }

          #onetrust-policy-text {
            text-align: center !important;
            font-family: theme($fontFamilies, 'AkzidenzGrotesk') !important;
            color: theme($colors, 'black') !important;
            line-height: 1.5 !important;
            font-size: 14px !important;
            margin-bottom: 0 !important;
            margin: 0 !important;

            @include from('xl') {
              text-align: left !important;
            }
          }
        }
      }

      #onetrust-button-group {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important ;
        gap: 15px;

        .banner-actions-container {
          display: flex !important;
          flex-direction: column-reverse !important;
          width: 100% !important;
          gap: 10px !important;
          max-width: unset !important;

          @include from('xl') {
            flex-direction: row-reverse !important;
            gap: 15px !important;
          }

          #onetrust-reject-all-handler,
          #onetrust-accept-btn-handler {
            flex: 1 1 !important;
            width: 100% !important;
            margin: 0 !important;
            font-family: theme($fontFamilies, 'AkzidenzGrotesk') !important;
            font-weight: theme($fontWeights, 'bold') !important;
            line-height: 1.6 !important;
            padding: theme($spacings, 14) theme($spacings, 50) !important;
            font-size: 12px !important;
            text-transform: uppercase !important;
            box-sizing: border-box !important;
            background: theme($colors, 'black') !important;
            border: 1px solid theme($colors, 'black') !important;
            border-radius: 0;
            min-width: fit-content !important;
          }

          #onetrust-reject-all-handler {
            background: theme($colors, 'white') !important;
            color: theme($colors, 'black') !important;
          }
        }
      }

      #onetrust-close-btn-container {
        position: static !important;
        width: fit-content !important;

        @include from('xl') {
          justify-self: center !important;
        }
      }
    }
  }
}
