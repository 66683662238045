@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.text {
  /**
  * GROTESK
  */
  &-12-grotesk-regular {
    @include font-base(12, 12, 'AkzidenzGrotesk', 1.3, 0em, 'regular');
  }
  &-12-grotesk-medium {
    @include font-base(12, 12, 'AkzidenzGrotesk', 1.3, 0em, 'medium');
  }
  &-16-12-grotesk-medium {
    @include font-base(16, 12, 'AkzidenzGrotesk', 1.3, 0em, 'medium');
  }

  &-14-grotesk-regular {
    @include font-base(14, 14, 'AkzidenzGrotesk', 1.5, 0em, 'regular');
  }

  &-12-sang-bleu {
    @include font-base(12, 12, 'SangBleu', 1.3, -0.01em, 'regular');
  }

  &-12-20-sang-bleu {
    @include font-base(12, 20, 'SangBleu', 1.1, -0.01em, 'light');
  }

  &-20-24-sang-bleu {
    @include font-base(20, 24, 'SangBleu', 1.4, -0.06em, 'light');
  }
}
