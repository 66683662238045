@import 'styles/theme';

.BigCloseIcon {
  width: 32px;
}

.MenuOpenIcon,
.MenuCloseIcon,
.ArrowRightIcon,
.DownIcon,
.BigLayoutIcon,
.MediumLayoutIcon,
.SmallLayoutIcon,
.MonoLayoutIcon {
  width: 16px;
}

.ArrowIcon {
  width: 6px;
}

.LeftIcon,
.RightIcon,
.BottomIcon,
.TopIcon {
  width: 4px;

  path {
    transition: fill 0.2s linear;
  }
}

.TopIcon {
  transform: rotateZ(90deg);
}

.BottomIcon {
  transform: rotateZ(-90deg);
}

.RightIcon {
  transform: rotateZ(180deg);
}

.QuestionMarkIcon {
  width: 8px;

  path {
    transition: fill 0.2s linear;
  }
}
