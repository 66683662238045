@import 'styles/theme';

.base-grid {
  display: grid;
  width: vw(100);
  max-width: 100%;
  grid-template-columns: repeat(theme($mobile, 'columns'), 1fr);
  grid-template-rows: 1fr;
  column-gap: theme($mobile, 'gap');
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: theme($mobile, 'gutter');
  padding-right: theme($mobile, 'gutter');

  @include from('md') {
    grid-template-columns: repeat(theme($desktop, 'columns'), 1fr);
    column-gap: theme($desktop, 'gap');
    grid-template-rows: 1fr;
    padding-left: theme($desktop, 'gutter');
    padding-right: theme($desktop, 'gutter');
  }

  @include from('max-grid-width') {
    width: theme($max, 'width');
  }
}
