@import 'styles/theme';

.Checkbox {
  .errorList {
    border-color: currentColor;
    text-align: left;
    color: theme($spacings, 'red');
    margin-top: theme($spacings, 5);
  }

  .checkmark {
    top: 2px;
    width: 17px;
    height: 17px;
  }

  .checkmarkActive {
    &.error {
      background-color: theme($spacings, 'red');
    }
  }

  .label {
    display: flex;
    align-items: center;
    margin-left: theme($spacings, 8);
    line-height: 1.25;
  }

  .checkIcon {
    width: 75%;
  }

  &.blackTheme {
    .checkmark {
      border-color: theme($colors, 'black');
    }

    .checkmarkActive {
      background-color: theme($colors, 'black');
    }
  }

  &.whiteTheme {
    .checkmark {
      border-color: theme($colors, 'white');
    }

    .checkIcon path {
      fill: theme($colors, 'axolotl');
    }

    .checkmarkActive {
      background-color: theme($colors, 'white');
    }
  }

  &.isSwitch {
    .content {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 24px;
    }

    .checkmark {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: 1px solid theme($colors, 'axolotl');
      transition: 0.4s;
      border-radius: 40px;
      opacity: 0.5;
    }

    .checkmark:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 1px;
      border-radius: 50%;
      background-color: theme($colors, 'axolotl');
      transition: transform 0.4s ease;
    }

    .checkmarkActive {
      display: none;
    }

    input:checked + div .checkmark {
      opacity: 1;
    }

    input:checked + div .checkmark:before {
      transform: translate3d(18px, 0, 0);
    }
  }

  &.round {
    .checkmark {
      top: 0;
      width: 17px;
      height: 17px;
      border-radius: 50%;
    }

    .checkmarkActive {
      top: 1px;
      left: 1px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }

    .content {
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }

    input:checked + .content,
    input:checked + .checkmarkActive {
      opacity: 1;
      transition: opacity 0.15s ease;
    }

    .checkIcon {
      display: none;
    }
  }

  &.squared {
    width: 100%;

    div.content {
      opacity: 0.5;
      padding: theme($spacings, 10) theme($spacings, 5);
      transition: opacity 0.15s ease;
    }

    .checkmark {
      display: none;
    }

    .Checkbox {
      width: 100%;
    }

    .checkmarkActive {
      top: 1px;
      left: 1px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }

    .checkIcon {
      display: none;
    }

    .label {
      width: 100%;
      margin-left: 0;
      display: block;
    }

    input:checked + .content {
      opacity: 1;
    }

    .container {
      width: 100%;
    }

    .content {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      padding: theme($spacings, 10);
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      word-wrap: break-word;
    }
  }
}
