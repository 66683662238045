@import 'styles/theme';

.NavBanner {
  pointer-events: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
  padding: 5px 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
  height: theme($bannerHeight, 'mobile');
  @include from('lg') {
    height: theme($bannerHeight, 'desktop');
  }

  .grid {
    position: relative;
    height: 100%;
  }

  .slider {
    grid-column: 1 / -1;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .textWrapper {
    padding: 0 16px;
    @include applyColumnWidth('padding-left', 2, $mobile);
    @include applyColumnWidth('padding-right', 2, $mobile);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    box-sizing: border-box;

    @include from('lg') {
      padding: 0 theme($spacings, 24);
    }
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    a {
      text-decoration: underline;
      text-underline-offset: theme($spacings, 3);
    }

    p {
      padding: 0.1em;
    }
  }

  .closeButton {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 101;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    @include applyColumnWidth('right', 1, $mobile);
    width: theme($spacings, 12);

    @include from('lg') {
      right: 24px;
    }

    .icon {
      width: 14px;
      height: 14px;
    }
  }
}
