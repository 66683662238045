@import 'styles/theme';

.ArrowLeftIcon {
  transform: rotateZ(180deg);
}

.ArrowBottomIcon {
  transform: rotateZ(90deg);
}

.whiteTheme {
  path {
    fill: theme($colors, 'white');
  }
}

.blackTheme {
  path {
    fill: theme($colors, 'black');
  }
}

.Search {
  width: 13px;
}
