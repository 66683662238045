@import './reset.scss';
@import './theme';
@import './cookie-pro.scss';

:root {
  --scroll-bar: 0px;
  --scroll-bar-grid: 0px;
  --vh100: 100vh;
  --banner-height: 0px;
  --banner-displayed: 0;
}

// FONTS
// Akzidenz Grotesk
@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url('/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.woff2')
      format('woff2'),
    url('/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.woff') format('woff');
  font-weight: theme($fontWeights, 'regular');
  font-display: swap;
}

@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url('/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.woff2')
      format('woff2'),
    url('/fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.woff') format('woff');
  font-weight: theme($fontWeights, 'medium');
  font-display: swap;
}

// Sang Bleu
@font-face {
  font-family: 'Sang Bleu';
  src: url('/fonts/SangBleu/SangBleu-Light.woff2') format('woff2'),
    url('/fonts/SangBleu/SangBleu-Light.woff') format('woff');
  font-weight: theme($fontWeights, 'light');
  font-display: swap;
}

@font-face {
  font-family: 'Sang Bleu';
  src: url('/fonts/SangBleu/SangBleu-Regular.woff2') format('woff2'),
    url('/fonts/SangBleu/SangBleu-Regular.woff') format('woff');
  font-weight: theme($fontWeights, 'regular');
  font-display: swap;
}

html,
body {
  background-color: var(--page-theme);
  transition: background-color 0.8s linear;
  width: 100%;
  color: theme($colors, 'black');
  font-family: theme($fontFamilies, 'AkzidenzGrotesk');
  font-weight: theme($fontWeights, 'regular');
  font-size: theme($fontSizes, 14);
  line-height: 1.4;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  @include toggleStableVh(100, 'lg', 'min-height');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

em {
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sb-show-main.sb-main-centered #root {
  padding: 0;
  box-sizing: border-box;
}

body {
  &.loading {
    cursor: wait;
  }

  &.preload * {
    transition: none !important;
  }
}

strong {
  font-weight: bold;
}

i {
  font-style: italic;
}

*,
a,
button,
input {
  &:focus {
    outline: theme($colors, 'axolotl') solid thin;
  }

  *:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: theme($colors, 'axolotl') solid thin;
  }
}

.rich-text-link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}

.hasBorders + .hasBorderTop,
.hasBorders + .hasBorders {
  border-top: none;
}
