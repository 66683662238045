@use 'sass:math';
@import 'styles/theme';

.NavigationDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .background,
  .topBackground {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center top;
  }

  .topBackground {
    height: theme($navHeight, 'desktop');
  }

  .topBackground {
    z-index: -1;
  }

  .navigationTop {
    position: relative;
    margin-top: theme($spacings, 35);
    margin-bottom: theme($spacings, 20);
    z-index: 2;
  }

  .leftContent,
  .rightContent {
    display: flex;
    align-items: center;
    grid-column: span 10;
  }

  .leftContent {
    grid-column: span 10;

    @include from('md') {
      .link {
        &.hasSubnav {
          cursor: default;
        }
      }
    }
  }

  .linksContainer {
    display: flex;
    align-items: center;

    .link + .link {
      margin-left: theme($spacings, 10);

      @include from('xl') {
        margin-left: theme($spacings, 14);
      }
    }
  }

  .centerContent {
    display: flex;
    justify-content: center;
    grid-column: span 4;

    .logo {
      display: inline-flex;
      align-items: center;
      width: 80%;
      max-width: 200px;
      margin: 0 auto;

      @include from('xl') {
        width: 100%;
      }
    }
  }

  .rightContent {
    margin-left: auto;
  }

  .currencySwitch {
    position: relative;
    padding-left: theme($spacings, 10);
    margin-left: theme($spacings, 10);

    @include from('xl') {
      padding-left: theme($spacings, 14);
      margin-left: theme($spacings, 14);
    }

    &:before {
      position: absolute;
      content: '';
      top: 3px;
      bottom: 3px;
      left: 0;
      width: 1px;
      background: theme($colors, 'black');
    }
  }

  // States
  &.isOpen {
    .logo path {
      fill: theme($colors, 'black');
    }

    .topBackground {
      opacity: 0;
    }
  }

  // Themes
  &.black {
    .topBackground {
      background-color: theme($colors, 'white');
    }
  }

  &.white {
    .topBackground {
      background-color: theme($colors, 'cod-gray');
    }

    .currencySwitch {
      &:before {
        background: theme($colors, 'white');
      }
    }
  }
}

.NavigationPanel {
  z-index: 1;
  position: relative;
  max-width: theme($max, 'width');
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  padding-top: theme($spacings, 5);
  padding-bottom: theme($spacings, 10);
  overflow-y: scroll;
  height: calc(
    #{vh(100)} - #{theme($navHeight, 'desktop') + theme($spacings, 5)}
  );

  /* Hide scrollbar by default */
  &::-webkit-scrollbar {
    width: theme($spacings, 6);
    background-color: theme($colors, 'concrete');
  }

  /* Make scrollbar visible when needed */
  &::-webkit-scrollbar-thumb {
    background-color: theme($colors, 'black');
  }

  /* Make scrollbar track visible when needed */
  &::-webkit-scrollbar-track {
    background-color: theme($colors, 'concrete');
  }

  .panelContent {
    pointer-events: all;
    height: 100%;
  }

  .panelTop {
    grid-column: 1/-1;
    position: relative;
  }

  .close {
    pointer-events: all;
    position: absolute;
    right: 0;
    padding: theme($spacings, 12) 0 theme($spacings, 12) theme($spacings, 12);
    z-index: 2;
  }

  .navItemCta {
    &.isSeeAll {
      margin-bottom: theme($spacings, 24);
    }
  }

  .panelBottom {
    margin-top: auto;
    padding-top: theme($spacings, 30);
    padding-bottom: theme($spacings, 20);
    grid-column: 1/12;
  }

  .bottomImage {
    position: absolute;
    bottom: theme($spacings, 30);
    right: theme($spacings, 20);
    @include applyColumnWidth('width', 9, $desktop);
    @include applyColumnWidth('height', 9, $desktop);
  }

  .ratio {
    @include applyPercentRatios(math.div(100, 100));
  }

  &.withBanner {
    height: calc(
      #{vh(100)} - #{theme($navHeight, 'desktop')} - #{theme(
          $bannerHeight,
          'desktop'
        )}
    );
  }
}

.NavigationItemCta {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  width: 100%;

  .link,
  .thirdLevelLinks,
  .viewMore {
    pointer-events: all;
  }

  .thirdLevelItems {
    display: flex;
  }

  .thirdLevelLinks {
    display: flex;
    // margin-left: theme($spacings, 8);
    padding-left: theme($spacings, 8);

    .thirdLevelLink {
      margin-right: theme($spacings, 16);
    }
  }

  .viewMore {
    color: theme($colors, 'silver-chalice');
  }
}
