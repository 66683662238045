@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.title {
  /**
  * Sang Bleu
  */
  &-15-sang-bleu {
    @include font-base(15, 15, 'SangBleu', 1, 0em, 'light');
  }
  &-20-16-sang-bleu {
    @include font-base(20, 16, 'SangBleu', 1, 0em, 'light');
  }
  &-18-sang-bleu {
    @include font-base(18, 18, 'SangBleu', 1.3, -0.01em, 'regular');
  }
  &-20-sang-bleu {
    @include font-base(20, 20, 'SangBleu', 1.3, -0.01em, 'regular');
  }
  &-20-sang-bleu-light {
    @include font-base(20, 20, 'SangBleu', 1.1, -0.01em, 'light');
  }
  &-24-sang-bleu {
    @include font-base(24, 24, 'SangBleu', 1.3, -0.01em, 'regular');
  }
  &-24-sang-bleu-light {
    @include font-base(24, 24, 'SangBleu', 1.3, -0.01em, 'light');
  }
  &-30-sang-bleu {
    @include font-base(30, 30, 'SangBleu', 1.6, -0.02em, 'light');
  }
  &-30-40-sang-bleu {
    @include font-base(30, 40, 'SangBleu', 1.6, -0.02em, 'light');
  }
  &-40-sang-bleu {
    @include font-base(40, 40, 'SangBleu', 1.2, -0.05em, 'light');
  }
  &-44-sang-bleu {
    @include font-base(44, 44, 'SangBleu', 1.3, -0.05em, 'light');
  }
  &-20-24-sang-bleu {
    @include font-base(20, 24, 'SangBleu', 1.3, -0.01em, 'light');
  }
  &-18-24-sang-bleu {
    @include font-base(20, 24, 'SangBleu', 1.3, -0.01em, 'light');
  }
  &-20-24-sang-bleu-regular {
    @include font-base(20, 24, 'SangBleu', 1.3, -0.01em, 'regular');
  }
  &-20-18-sang-bleu {
    @include font-base(20, 18, 'SangBleu', 1.3, -0.01em, 'regular');
  }

  // Fluid
  &-fluid-20-40-sang-bleu {
    @include font-fluid-base(20, 40, 'SangBleu', 1.4, -0.05em, 'light');
  }

  &-fluid-24-40-sang-bleu {
    @include font-fluid-base(24, 40, 'SangBleu', 1.4, -0.05em, 'light');
  }

  &-fluid-34-56-sang-bleu {
    @include font-fluid-base(34, 56, 'SangBleu', 1.3, -0.03em, 'light');
  }

  &-fluid-90-240-sang-bleu {
    @include font-fluid-base(90, 240, 'SangBleu', 1, -0.05em, 'light');
  }

  &-fluid-38-144-sang-bleu {
    @include font-fluid-base(38, 144, 'SangBleu', 1, -0.05em, 'light');
  }

  &-fluid-64-214-sang-bleu {
    @include font-fluid-base(64, 214, 'SangBleu', 1, -0.05em, 'light');
  }
}
