@import 'styles/theme';

.PageWrapper {
  position: relative;
}

.Page {
  position: relative;

  &.withoutMarginTop {
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }
    }
  }

  &.withoutMarginBottom {
    > * {
      &:first-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.withBanner {
    padding-top: theme($bannerHeight, 'mobile');
  }
}
