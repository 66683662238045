@import 'styles/theme';

.Navigation {
  @include switchDesktopMobileClasses('lg', flex);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .logo path {
    transition: fill 0.2s linear;
  }

  &.hideLogo {
    .logo {
      opacity: 0;
    }
  }

  &.noClickLogo {
    .logo {
      pointer-events: none;
    }
  }
}

.NavigationScrollRef {
  visibility: hidden;
  position: absolute;
  top: theme($spacings, 10);
  width: 100%;
  height: 1px;
}
