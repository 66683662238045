@import 'styles/theme';

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.light {
  font-weight: theme($fontWeights, 'light');
}

.medium {
  font-weight: theme($fontWeights, 'medium');
}

.bold {
  font-weight: theme($fontWeights, 'bold');
}
