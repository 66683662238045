@import 'styles/theme';

.Input {
  @include setThemeClasses();

  .input {
    border-color: var(--theme-main);
    color: var(--theme-main);
    box-sizing: border-box;
    padding: theme($spacings, 10) theme($spacings, 15);

    &::placeholder {
      color: theme($colors, 'gray');
    }

    &.error {
      border-color: currentColor;
      color: theme($colors, 'red');
    }

    + .errorList {
      color: theme($colors, 'red');
      margin-top: theme($spacings, 5);
    }
  }

  &.birthDate {
    .input {
      height: 100%;
      padding: theme($spacings, 15) theme($spacings, 20);
    }
  }

  &.search {
    .input {
      border: none;
      padding: 0;
    }
  }

  input[type='date'] {
    position: relative;
  }

  // trick to keep date input with calender at the same height as text input
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    right: theme($spacings, 20);
    width: 12px;
    padding: 0px;
    margin: 0px;
  }
}
