@import 'styles/theme';

.Select {
  @include setThemeClasses();

  .select {
    border-color: var(--theme-main);
    padding: theme($spacings, 7) theme($spacings, 24) theme($spacings, 7)
      theme($spacings, 10);
    line-height: normal;

    &.error {
      border-color: currentColor;
      color: theme($colors, 'red');

      .icon {
        fill: theme($colors, 'red');
      }
    }
  }

  .errorList {
    margin-top: theme($spacings, 5);
  }

  .icon {
    fill: var(--theme-main);
    right: theme($spacings, 6);
  }

  .error {
    color: theme($colors, 'red');
    fill: theme($colors, 'red');

    .select {
      border-color: theme($colors, 'red');
    }

    .icon {
      fill: theme($colors, 'red');
    }
  }
}
