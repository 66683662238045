@use 'sass:math';
@import 'styles/theme';

.NavigationMobile {
  position: relative;

  .top {
    position: relative;
    padding-top: theme($spacings, 25);
    padding-bottom: theme($spacings, 25);
    width: calc(100% - theme($spacings, 12));
    margin-left: theme($spacings, 6);
    z-index: 2;
  }

  .left,
  .right {
    grid-column: span 5;
    @include from('md') {
      grid-column: span 9;
    }
  }

  .center {
    display: flex;
    align-items: center;
    grid-column: span 6;
    justify-content: center;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .logo {
    height: 16px;
    width: auto;
    max-width: 100%;
    visibility: hidden;

    &.isOpen {
      visibility: visible;
    }
  }

  .topBackground,
  .background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center top;
    box-sizing: border-box;
  }

  .topBackground {
    z-index: 0;
    height: theme($navHeight, 'mobile');
  }

  .background {
    border: 6px solid theme($colors, 'silver');
    z-index: 1;
  }

  .navigationPanel {
    position: relative;
    z-index: 1;
    padding-bottom: theme($spacings, 6);
    width: calc(100% - theme($spacings, 12));
    margin-left: theme($spacings, 6);
    height: calc(#{stableVh(100)} - #{theme($navHeight, 'mobile')});
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .navigationPanelItem {
    border-top: 1px solid theme($colors, 'silver');
    height: 100%;
    overflow-y: scroll;
  }

  .navigationPanelBottom {
    margin-top: auto;

    .navigationPanelBottomTop {
      display: flex;
      border-top: 1px solid theme($colors, 'silver');

      .navigationPanelBottomTopItem {
        text-align: left;
        flex: 1;
        padding: theme($spacings, 14) theme($spacings, 14);

        & + .navigationPanelBottomTopItem {
          border-left: 1px solid theme($colors, 'silver');
        }
      }
    }

    .navigationPanelBottomBody {
      display: flex;
      flex-flow: row wrap;
      padding: theme($spacings, 12) 0;
      border-top: 1px solid theme($colors, 'silver');

      .navigationPanelBottomBodyItem {
        width: 50%;
        box-sizing: border-box;
        padding: theme($spacings, 4) theme($spacings, 14);
        text-align: left;
      }
    }

    .navigationPanelBottomBottom {
      border-top: 1px solid theme($colors, 'silver');

      .copyright {
        display: block;
        color: theme($colors, 'gray');
        padding: theme($spacings, 14) theme($spacings, 14);
      }
    }
  }

  // States
  &.isOpen {
    .logo path,
    path {
      fill: theme($colors, 'black');
    }

    .topBackground {
      opacity: 0;
    }

    &.withBanner {
      margin-top: -#{theme($bannerHeight, 'mobile')};
    }
  }

  &.black {
    .topBackground {
      background-color: theme($colors, 'white');
    }
  }

  &.white {
    .topBackground {
      background-color: theme($colors, 'cod-gray');
    }
  }
}

.NavigationPanel {
  .navigationPanelTop {
    .navigationPanelFirstLevel {
      display: flex;
      flex-direction: column;

      .navigationPanelFirstLevelItem {
        display: flex;
        flex-direction: column;
      }

      .link {
        border-bottom: 1px solid theme($colors, 'silver');
        display: inline-flex;
        box-sizing: border-box;
        justify-content: flex-start;
        padding: theme($spacings, 16) theme($spacings, 14);
        width: 100%;
        align-items: center;

        .linkMore {
          margin-left: auto;
        }
      }
    }

    .search {
      padding: theme($spacings, 30) theme($spacings, 14);
    }
  }
}

.NavigationSubPanel {
  padding-bottom: theme($spacings, 20);
  padding-top: theme($spacings, 20);

  .panelTop {
    overflow: hidden;
  }

  .secondLevelItem {
    overflow: hidden;

    & + .secondLevelItem {
      margin-top: theme($spacings, 12);
    }
  }

  .secondLevelLink {
    align-items: flex-start;
    display: flex;
    padding-left: theme($spacings, 14);
    padding-right: theme($spacings, 14);

    .link {
      line-height: 1.2;
      text-align: start;
    }

    .trigger {
      display: flex;
      height: 100%;
      color: theme($colors, 'gray');
      padding-left: theme($spacings, 8);
      padding-right: theme($spacings, 20);
      padding-top: theme($spacings, 16);
    }
  }

  .secondLevelEntriesWrapper {
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid theme($colors, 'alto');
    padding-bottom: theme($spacings, 10);
    margin-right: -1px;

    .emptySpace,
    .thirdLevelItem {
      position: relative;
      box-sizing: border-box;

      border-right: 1px solid theme($colors, 'alto');
      border-bottom: 1px solid theme($colors, 'alto');
      width: math.div(1, 3) * 100%;
    }

    .emptySpace {
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 200%;
        background-color: theme($colors, 'alto');
        opacity: 1;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    .thirdLevelItem {
      position: relative;

      &.hasNoImage {
        background: transparent;
        padding-bottom: calc(#{math.div(1, 3) * 100%} - 1px);
      }

      .ratio {
        padding-bottom: 100%;
      }

      .label {
        display: block;
        text-align: left;
        padding: theme($spacings, 8) theme($spacings, 14);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .panelBottom {
    box-sizing: border-box;
    width: 70%;
    padding-left: theme($spacings, 14);
    margin-top: theme($spacings, 20);
  }
}
