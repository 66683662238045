@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.cta {
  // Grotesk
  &-10-grotesk {
    @include font-base(10, 10, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  &-11-grotesk {
    @include font-base(11, 11, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  &-10-grotesk-regular {
    @include font-base(10, 10, 'AkzidenzGrotesk', 1, -0.01em, 'regular');
  }

  &-10-12-grotesk {
    @include font-base(10, 12, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  &-12-grotesk {
    @include font-base(12, 12, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  &-12-grotesk-regular {
    @include font-base(12, 12, 'AkzidenzGrotesk', 1, -0.01em, 'regular');
  }

  &-12-10-grotesk-regular {
    @include font-base(12, 10, 'AkzidenzGrotesk', 1, -0.01em, 'regular');
  }

  &-11-12-grotesk {
    @include font-base(11, 12, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  &-10-12-grotesk-regular {
    @include font-base(10, 12, 'AkzidenzGrotesk', 1, -0.01em, 'regular');
  }

  &-16-grotesk {
    @include font-base(16, 16, 'AkzidenzGrotesk', 1, -0.01em, 'medium');
  }

  // Sang Bleu
  &-16-12-sang-bleu {
    @include font-base(12, 16, 'SangBleu', 1.3, -0.01em, 'light');
  }
  &-18-sang-bleu {
    @include font-base(18, 18, 'SangBleu', 1, -0.01em, 'regular');
  }
  &-30-sang-bleu {
    @include font-base(30, 30, 'SangBleu', 1.1, -0.01em, 'light');
  }
}
