@import 'styles/theme';

.input {
  /**
  * GROTESK
  */

  &-12-grotesk-regular {
    @include font-base(12, 12, 'AkzidenzGrotesk', 1.3, 0em, 'regular');
  }

  /**
  * SANG BLEU
  */

  &-30-56-sang-bleu {
    @include font-base(30, 56, 'SangBleu', 1, -0.05em, 'light');
  }
}
