@import 'styles/theme';

$panelMaxWith: 450px;

.PanelManager {
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: stableVh(100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;

  &.enterFromTop {
    z-index: 9;
  }

  @include from('lg') {
    top: 0;
    height: 100%;
    width: 100%;
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &.enterFromLeft {
      align-items: flex-start;
    }

    &.enterFromTop {
      align-items: flex-start;
      flex-direction: row;
      z-index: 9;

      .content {
        height: auto;

        @include from('lg') {
          width: 100%;
        }
      }
    }
  }

  .background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: auto;
    opacity: 0;
  }

  .closeIcon {
    display: none;

    @include from('lg') {
      position: fixed;
      top: 20px;
      width: 30px;
      margin-right: theme($spacings, 20);
      display: block;
      z-index: 1;

      @include applyColumnWidth('right', 8, $desktop);
    }

    @include from('xl') {
      right: min(getGridColumnMaxWidth(7, $desktop), $panelMaxWith);
    }
  }

  .content {
    pointer-events: auto;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: theme($colors, 'white');
    overflow: hidden;
    transform: translateX(100%);
    max-width: $panelMaxWith;

    @include from('lg') {
      @include applyColumnWidth('width', 8, $desktop);
    }

    @include from('xl') {
      @include applyColumnWidth('width', 7, $desktop);
    }
  }

  .children {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  // States
  &.enterFromTop {
  }
}
