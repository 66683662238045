@import 'styles/theme';

.NavigationLogo {
  width: 64px;

  &.black-theme {
    path {
      fill: theme($colors, 'black');
    }
  }
}
