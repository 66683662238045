@import 'styles/theme';

.Filter {
  .column {
    padding-bottom: theme($spacings, 10);
    border-bottom: 1px solid rgba(177, 177, 177, 0.2);

    &.isolatedFilter {
      border: none;

      &:first-child {
        margin-bottom: theme($spacings, 30);
      }

      &:last-child {
        margin-top: theme($spacings, 30);
      }
    }

    + .column {
      margin-top: theme($spacings, 30);
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .columnTitle {
        text-align: left;
      }

      .stateIndicator {
        flex-shrink: 0;

        .char {
          display: inline-block;
          width: 10px;
        }
      }
    }

    .columnFilters {
      padding-bottom: theme($spacings, 10);
    }
  }

  .checkbox {
    &:first-child {
      margin-top: theme($spacings, 16);
    }
    + .checkbox {
      margin-top: theme($spacings, 8);
    }
  }

  .label {
    margin-left: theme($spacings, 8);
  }

  .radio {
    width: calc(50% - theme($spacings, 4));
    display: inline;

    &:nth-child(even) {
      margin-left: theme($spacings, 8);
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: theme($spacings, 16);
    }

    &:not(:nth-child(1)):not(:nth-child(2)) {
      margin-top: theme($spacings, 10);
    }
  }

  .columnFilters.order_by {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
