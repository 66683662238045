@import 'styles/theme';

$mobileClampedMargin: clamp(theme($spacings, 8), stableVh(16), 64px);
.ScrollIntro {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: $mobileClampedMargin;
  z-index: 10;
  pointer-events: none;
  position: sticky;
  top: 28px;
  margin-top: stableVh(16);

  @include from('lg') {
    top: 39px;
    margin-top: clamp(32vh, 320px, 480px);
    margin-bottom: theme($spacings, 10);
  }

  @include from('xl') {
    top: theme($spacings, 35);
  }

  &.hide {
    .logo {
      opacity: 0;
      transition: opacity 0s ease-in-out 0s;
    }
  }

  .logo {
    width: calc(100% - theme($spacings, 40));
    position: sticky;
    top: 35px;
    box-sizing: border-box;
    margin: 0 auto;
    transition: opacity 0s ease-in-out;
  }

  .logo .hide {
    width: 100%;
    display: flex;
    align-items: flex-start;
    opacity: 0;

    svg {
      width: 100%;
    }
  }

  .innerMarker {
    height: 1px;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .movingLogo {
    width: 100%;

    svg {
      transform-origin: center 0%;
      width: 100%;
    }

    .h1 {
      border: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      white-space: nowrap;
    }
  }

  .logoItem {
    display: block;
    opacity: 0;
    path {
      fill: black;
    }

    &.displayed {
      opacity: 1;
    }
  }

  .markerWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    .marker {
      display: flex;
      grid-column: 6/-6;

      @include from('lg') {
        padding-left: 10%;
        padding-right: 10%;
        grid-column: 11/-11;
      }

      @include from('xl') {
        padding-left: 0;
        padding-right: 0;
      }
      svg {
        opacity: 0;
        pointer-events: none;
      }
    }

    .innerMarker {
      display: inline-flex;
      max-width: theme($spacings, 200);
      margin: 0 auto;
      width: auto;
      height: 16px;
      @include from('lg') {
        width: 80%;
      }
      @include from('xl') {
        width: 100%;
      }
    }
  }

  &.withBanner {
    padding-top: theme($spacings, 40);
  }
}

.logo.hidden {
  position: absolute;
  left: theme($spacings, 20);
  pointer-events: none;
  padding: 0;
  margin-top: calc(#{$mobileClampedMargin} * -1);
  width: calc(100% - theme($spacings, 40));
  transform: translate3d(0, calc(-100%), 0);

  @include from('lg') {
    margin-top: -10px;
    transform: translate3d(0, calc(-100%), 0);
  }

  .logoItem {
    opacity: 0;
  }

  .scaleMarker {
    width: 100%;
  }
}
