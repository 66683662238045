@import 'styles/theme';

.viewMore {
  margin-top: theme($spacings, 10);
}

.subValues {
  display: none;

  &.visible {
    display: block;
  }

  .subValue {
    margin-left: theme($spacings, 25);
  }
}
