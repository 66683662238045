@import 'styles/theme';

.Form {
  @include switchDesktopMobileClasses('lg');

  .filtersList {
    overflow: scroll;
    height: 100%;
    position: relative;
    margin-bottom: theme($spacings, 20);
  }

  .bottom {
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;

    .submit {
      width: 100%;
    }
  }
}
