@import 'styles/theme';

.InformationTag {
  display: inline-flex;
  align-items: center;
  padding: theme($spacings, 4) theme($spacings, 8);
  border: 1px solid theme($colors, 'gray');
  color: theme($colors, 'gray');
  border-radius: theme($spacings, 20);
  margin-right: theme($spacings, 4);
}
