@import 'styles/theme';

.Icon {
}

.BigArrowRightIcon {
  transform: rotate(180deg);
}

.ArrowRightIcon {
  transform: translate3d(0, -4%, 0) rotate(180deg);
}

.StoreLocator {
  width: 10px;
}

.StarIcon {
  width: 10px;
  height: 8px;
}

.ArrowLeftIcon,
.ArrowRightIcon {
  &.pampasTheme path {
    fill: theme($colors, 'pampas');
  }

  &.axolotlTheme path {
    fill: theme($colors, 'axolotl');
  }
}
