@use 'sass:math';

@import './mixins';
/*
  COLORS
*/
$colors: (
  'white': #ffffff,
  'black': #000000,
  'concrete': #f3f3f3,
  'scorpion': #5c5c5c,
  'silver-chalice': #b1b1b1,
  'alto': #d2d2d2,
  'silver': #c0c0c0,
  'gray': #828282,
  'cod-gray': #1b1b1b,
  'mine-shaft': #2c2c2c,
  'william': #3e5d6d,
  'red': #ff0000,
  'eunry': #cb9e95,
  'irish-coffee': #584024,
  'amber': #f3c300,
  'transparent': transparent,
);

/*
  GRID
*/
$mobile: (
  'columns': 16,
  'gap': 8px,
  'gutter': 20px,
);

$desktop: (
  'columns': 24,
  'gap': 8px,
  'gutter': 20px,
);

$max: (
  'width-design': 1440px,
  'width': 1940px,
);

$bannerHeight: (
  'mobile': 40px,
  'desktop': 40px,
);

/*
  FONTS
*/
$AkzidenzGrotesk: 'Akzidenz Grotesk', sans-serif;
$SangBleu: 'Sang Bleu', serif;

$fontFamilies: (
  AkzidenzGrotesk: $AkzidenzGrotesk,
  SangBleu: $SangBleu,
);

$fontSizes: (
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  19: 19px,
  20: 20px,
  22: 22px,
  24: 24px,
  25: 25px,
  27: 27px,
  30: 30px,
  35: 35px,
  40: 40px,
  44: 44px,
  56: 56px,
  60: 60px,
  80: 80px,
  90: 90px,
  190: 190px,
);

$fluidFontSizes: (
  20: 20px,
  24: 24px,
  25: 25px,
  34: 34px,
  38: 38px,
  40: 40px,
  64: 64px,
  56: 56px,
  60: 60px,
  90: 90px,
  144: 144px,
  190: 190px,
  214: 214px,
  240: 240px,
);

$fontWeights: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700,
);

// MARGIN AND PADDING
$spacings: (
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  20: 20px,
  21: 21px,
  22: 22px,
  24: 24px,
  25: 25px,
  26: 26px,
  28: 28px,
  30: 30px,
  35: 35px,
  34: 34px,
  36: 36px,
  37: 37px,
  40: 40px,
  42: 42px,
  45: 45px,
  48: 48px,
  50: 50px,
  55: 55px,
  56: 56px,
  60: 60px,
  65: 65px,
  70: 70px,
  75: 75px,
  80: 80px,
  90: 90px,
  95: 95px,
  100: 100px,
  110: 110px,
  120: 120px,
  135: 135px,
  150: 150px,
  160: 160px,
  175: 175px,
  180: 180px,
  190: 190px,
  200: 200px,
  220: 220px,
  250: 250px,
  325: 325px,
);

/*
  SCREENS
*/
$screens: (
  xxxs: 375px,
  xxs: 480px,
  xs: 576px,
  sm: 640px,
  md: 768px,
  l: 930px,
  lg: 1024px,
  xl: 1280px,
  xxl: map-get($max, 'width-design'),
  'max-grid-width': map-get($max, 'width'),
);

/*
  RATIO
*/
$ratio: (
  '2/3': math.div(2, 3),
);

@function getBannerHeight($height) {
  @return calc(#{$height} + (var(--banner-height) * var(--banner-displayed)));
}

$navHeight: (
  'mobile': 70px,
  'mobileWithBanner': getBannerHeight(70px),
  'desktop': 75px,
  'desktopWithBanner': getBannerHeight(75px),
  'banner': 40px,
);

$easings: (
  'ease-in-out': cubic-bezier(0.56, 0.21, 0.48, 0.85),
);
