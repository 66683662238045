@import 'styles/theme';

.rich-text {
  &-editorial {
    // Fonts display
    h2 {
      @include font-base(40, 40, 'SangBleu', 1.2, -0.05em, 'light');
    }

    h5 {
      @include font-base(12, 12, 'SangBleu', 1, 0, 'light');
    }

    p,
    p a {
      @include font-base(14, 14, 'AkzidenzGrotesk', 1.3, 0, 'regular');
    }

    b,
    b a {
      @include font-base(12, 12, 'AkzidenzGrotesk', 1.3, 0, 'medium');
      @include font-uppercase();
    }

    p i {
      @include font-base(12, 12, 'SangBleu', 1.3, 0, 'light');
    }

    a {
      text-decoration: underline;
    }

    // Alignment

    // Spacings
    h2 {
      & + h3,
      & + p,
      & + ul {
        margin-top: theme($spacings, 56);
      }
    }

    h3 {
      & + p,
      & + b,
      & + ul {
        margin-top: theme($spacings, 16);
      }
    }

    p,
    b,
    ul,
    h5 {
      & + h2,
      & + h3 {
        margin-top: theme($spacings, 56);
      }

      & + p,
      & + b,
      & + ul {
        margin-top: theme($spacings, 16);
      }
    }

    p,
    b,
    ul {
      & + h5 {
        margin-top: theme($spacings, 40);
      }
    }

    a {
      color: theme($colors, 'tall-poppy');
    }

    // Globals
    :global(.centered) {
      display: block;
      text-align: center;
    }

    :global(.ctaWrapper) {
      margin-top: theme($spacings, 24);
    }
  }
}
