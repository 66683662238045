@import 'styles/theme';

.Ratio {
  position: relative;
  width: 100%;

  .ratioContainer {
    width: 100%;
    overflow: hidden;
  }

  .children {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
