@import 'styles/theme';

.LoadingBar {
  position: fixed;
  z-index: 1000;
  top: -2px;
  left: 0px;
  width: 100%;
  height: 2px;
  overflow: hidden;
  will-change: transform;
  opacity: 0;
  transition: transform 0.25s, opacity 0.25s;

  &.isVisible {
    opacity: 1;
    transform: translate3d(0, 2px, 0);
  }

  .loadingProgress {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'black');
    left: -100%;
  }
}
