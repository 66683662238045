@import 'styles/theme';

.PopInCloseIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: theme($spacings, 10);
  cursor: pointer;
  z-index: 1;
  right: theme($spacings, 10);
  top: theme($spacings, 8);

  svg {
    width: theme($spacings, 16);
    height: theme($spacings, 16);
  }

  path {
    fill: theme($colors, 'black') !important;
  }
}

.close {
  position: absolute;
  top: theme($spacings, 25);
  right: theme($spacings, 15);
  fill: black;
  z-index: 1;

  @include from('lg') {
    top: theme($spacings, 40);
    right: theme($spacings, 25);
  }
}

.PopIn {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  @include from('md') {
    width: auto;
  }
}
